/* Code for mui-datatables */

import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, Modal, Slider, Button, TableFooter, TableRow, TablePagination, LinearProgress } from '@mui/material';
import { CSVLink } from 'react-csv';

const Table = ({ products, onProductsUpdate }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = sessionStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [showCartModal, setShowCartModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedProductNames, setSelectedProductNames] = useState([]);
  const [ageRange, setAgeRange] = useState([0, 100]);
  const [valueRange, setValueRange] = useState([0, 10000000]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(products.length);
  const [isLoading, setIsLoading] = useState(false);
  // const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    console.log('Total de productos recibidos:', products.length);
    setTotalCount(products.length);
  }, [products]);

  useEffect(() => {
    const updatedSelectedRows = products
      .map((product, index) => (cart.some((item) => item.Sample_ID === product.Sample_ID) ? index : null))
      .filter((index) => index !== null);
    setSelectedRows(updatedSelectedRows);
  }, [cart, products]);

  useEffect(() => {
    if (showCartModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCartModal]);

  const handleRowSelectionChange = (currentRowSelected, allRowSelected, rowsSelected) => {
    if (allRowSelected.length > 0) {
      setSelectedRows(rowsSelected);
    }
    setButtonDisabled(rowsSelected.length === 0);
  };

  const handleRemoveItem = (sampleId) => {
    setCart((prevCart) => prevCart.filter((item) => item.Sample_ID !== sampleId));
  };

  const handleRemoveSelectedItems = () => {
    setCart((prevCart) => prevCart.filter((item) => !selectedRows.some((index) => item.Sample_ID === products[index].Sample_ID)));
    setSelectedRows([]);
    setButtonDisabled(true);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  const handleRemoveMultipleItems = () => {
    setCart((prevCart) => prevCart.filter((item) => !selectedProductNames.includes(item.ProductName)));
    setShowRemoveModal(false);
  };

  const handleCheckboxChange = (productName) => {
    setSelectedProductNames((prevSelected) => {
      if (prevSelected.includes(productName)) {
        return prevSelected.filter((name) => name !== productName);
      } else {
        return [...prevSelected, productName];
      }
    });
  };

  const handleAddToCart = () => {
    const updatedCart = [...cart];
    selectedRows.forEach((rowIndex) => {
      const product = products[rowIndex];
      if (!updatedCart.some((item) => item.Sample_ID === product.Sample_ID)) {
        updatedCart.push(product);
      }
    });
    setCart(updatedCart);
    setSelectedRows([]);
    // setButtonDisabled(true);
  };

  const customPaginationTotalRender = () => {
    if (rowsPerPage === 'All') {
      return `Showing all ${totalCount} products`;
    }

    const from = page * rowsPerPage;
    let to = Math.min((page + 1) * rowsPerPage) - 1;

    if (to >= products.length) {
      to = products.length - 1;
    }
    return `${from + 1}-${to + 1} of ${products.length}`;
  };

  const handleTableChange = async (action, tableState) => {
    if (action === 'changePage' || action === 'changeRowsPerPage') {
      try {
        setIsLoading(true);
        const newPage = tableState.page;
        const newRowsPerPage = tableState.rowsPerPage;
        console.log('newPage',newPage)
        console.log('newRowsPerPage',newRowsPerPage)
        // Guardar la posición actual del scroll
        const scrollPosition = window.scrollY;

        setPage(newPage);
        setRowsPerPage(newRowsPerPage);

        const searchQuery = products.length > 0 ? encodeURIComponent(products[0]?.ProductName || '') : '';

        // Solo realizar la búsqueda si el `searchQuery` tiene un valor válido
        if (searchQuery) {
          console.log('Realizando búsqueda con:', searchQuery);
          // const response = await fetch(
          //   `${process.env.REACT_APP_API_URL}/prod/api/v1/products/${searchQuery}`
          // );
          // if (!response.ok) throw new Error('Network response was not ok');
          // const data = await response.json();
          const data = { 'products': products }

          console.log('Respuesta de la api:', data);
          

          // if (data && Array.isArray(data.products)) {
          //   console.log('Productos:', data.products);

          //   const updatedProducts = [...products]; // Acumulamos los nuevos productos

          //   // Actualizar el estado de los productos con los nuevos productos
          //   onProductsUpdate(updatedProducts);
          //   // console.log('Productos:', data.products);

          //   const newTotalCount = data.totalCount || updatedProducts.length;

          //   console.log('Nuevo total de registros:', newTotalCount);
          //   setTotalCount(newTotalCount);
          // } else {
          //   console.error('Invalid data received:', data);
          // }
        } else {
          console.log('No search query available, skipping API call.');
          // Si no hay query de búsqueda, solo carga todos los productos.
          await loadAllProducts();
        }

        // Restaurar la posición del scroll después de la actualización
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 100);
      } catch (error) {
        console.error('Error updating table:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const loadAllProducts = async () => {
    console.log('Executing loadAllProducts');
    setIsLoading(true);
    try {
      // const BATCH_SIZE = 100; // Reducir el tamaño del lote para mejor rendimiento
      let allData = [];

      while (true) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prod/api/v1/productname?size=10000&search=${encodeURIComponent(products[0]?.ProductName || '')}`
        );

        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();

        allData = [...allData, ...data];

        // if (data.length < 100) break;

        // // Actualizar progresivamente
        // setAllProducts((prevData) => [...prevData, ...data]);
      }
    } catch (error) {
      console.error('Error loading all products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanUpCart = (cart) => {
    var cleanCart = [];
    // console.log('Cart Content:',cart)
    cart.map((item) => {
      // console.log(item._id)
      return cleanCart.push({
        'Sample ID': item.Sample_ID,
        Age: item.Age,
        Gender: item.Gender,
        'Product Name': item.ProductName,
        Value: item.ValueFld,
        'Unit of Measure': item.Unit_of_Measure,
        'Sample Type': item.SampleType,
        'Collection Date': item.Collection_Date,
        Volumen: item.TotalCurrentAmount,
        'Thaw/Freeze': item.Thaw_Freeze,
        Instrument: item.Instrument,
        Notes: item.Notes,
      });
    });
    // console.log('CleanCart:',cleanCart)
    return cleanCart;
  };

  const minAge = Math.min(...products.filter((product) => product.Age != null).map((product) => product.Age)) || 0;
  const maxAge = Math.max(...products.filter((product) => product.Age != null).map((product) => product.Age)) || 100;

  const minValue = Math.min(...products.filter((product) => product.ValueFld != null).map((product) => product.ValueFld)) || 0;
  const maxValue = Math.max(...products.filter((product) => product.ValueFld != null).map((product) => product.ValueFld)) || 1000;

  const uniqueProductNames = [...new Set(cart.map((product) => product.ProductName))];

  const hasNotes = products.some((product) => product.Notes && product.Notes.trim() !== '');

  const getSelectedProducts = useCallback(() => {
    console.log('Executing getSelectedProducts');

    const productsToDownload = selectedRows.length === products.length ? products : products.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    return selectedRows
      .map((index) => {
        const product = productsToDownload[index];
        if (!product) return null;

        return {
          'Sample ID': product.Sample_ID || '',
          Age: product.Age || '',
          Gender: product.Gender || '',
          'Product Name': product.ProductName || '',
          Value: product.ValueFld || '',
          'Unit of Measure': product.Unit_of_Measure || '',
          'Sample Type': product.SampleType || '',
          'Collection Date': product.Collection_Date ? new Date(product.Collection_Date).toLocaleDateString() : '',
          Volume: product.TotalCurrentAmount || '',
          'Thaw/Freeze': product.Thaw_Freeze || '',
          Instrument: product.Instrument || '',
          Notes: product.Notes || '',
        };
      })
      .filter(Boolean);
  }, [selectedRows, products, rowsPerPage, page]);

  const columnsTable = [
    {
      name: 'Sample_ID',
      label: 'Sample ID',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '400px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Age',
      label: 'Age',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Roboto', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
        filter: true,
        customFilterListOptions: {
          render: () => `Age: ${ageRange[0]} - ${ageRange[1]}`,
        },
        filterType: 'custom',
        filterOptions: {
          logic(age) {
            return age < ageRange[0] || age > ageRange[1];
          },
          display: () => (
            <Box sx={{ width: 200, padding: 2 }}>
              <p style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'flex-start' }}>Age</p>
              <Slider
                sx={{ color: 'rgb(58, 12, 207)' }}
                value={ageRange}
                onChange={(_, newValue) => setAgeRange(newValue)}
                valueLabelDisplay="auto"
                min={minAge}
                max={maxAge}
              />
            </Box>
          ),
        },
      },
    },
    {
      name: 'Gender',
      label: 'Gender',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'ProductName',
      label: 'Product Name',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'ValueFld',
      label: 'Value',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),

        filter: true,
        customFilterListOptions: {
          render: () => `Value: ${valueRange[0]} - ${valueRange[1]}`,
        },
        filterType: 'custom',
        filterOptions: {
          logic(value) {
            return value < valueRange[0] || value > valueRange[1];
          },
          display: () => (
            <Box sx={{ width: 200, padding: 2 }}>
              <p style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'flex-start' }}>Value</p>
              <Slider
                sx={{ color: 'rgb(58, 12, 207)' }}
                value={valueRange}
                onChange={(_, newValue) => setValueRange(newValue)}
                valueLabelDisplay="auto"
                min={minValue}
                max={maxValue}
              />
            </Box>
          ),
        },
      },
    },
    {
      name: 'Unit_of_Measure',
      label: 'Units',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'SampleType',
      label: 'Sample Type',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Collection_Date',
      label: 'Collection Date',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'TotalCurrentAmount',
      label: 'Volume (mLs)',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Thaw_Freeze',
      label: '# of Thaw-Freeze',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Instrument',
      label: 'Instrument',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '300px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    ...(hasNotes
      ? [
          {
            name: 'Notes',
            label: 'Notes',
            options: {
              setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
              setRowProps: () => ({ style: { height: '35px' } }),
            },
          },
        ]
      : []),
  ];

  const optionsTable = {
    filter: true,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filterType: 'multiselect',
    responsive: 'standard',
    selectableRows: 'multiple',
    selectableRowsOnPage: false,
    onRowSelectionChange: handleRowSelectionChange,
    rowsSelected: selectedRows,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 50, 100, 200, { label: 'All', value: 'All' }],
    tableLayout: 'auto',
    onTableChange: handleTableChange,
    progressPending: isLoading,
    serverSide: rowsPerPage === 'All',
    count: totalCount,
    page: page,
    sort: true,
    customToolbarSelect: () => null,
    fixHeader: 'true',
    customTableBodyFooter: () => <div style={{ height: '40px', textAlign: 'center' }}></div>,
    setRowProps: (rowData, rowIndex) => ({
      sx: {
        '& .MuiTableRow-root:hover': {
          backgroundColor: 'inherit !important',
        },
        backgroundColor: `${rowIndex % 2 !== 0 ? 'rgba(57, 12, 207, 0.3)' : '#ffffff'} !important`,
        '&:hover': {
          backgroundColor: `${rowIndex % 2 !== 0 ? 'rgba(57, 12, 207, 0.3)' : '#ffffff'} !important`,
        },
      },
    }),

    setTableProps: () => ({
      sx: {
        '& .MuiTableCell-head': {
          borderRight: '2px solidrgba(255, 255, 255, 0)',
          fontWeight: 'bold',
          padding: '10px',
          textAlign: 'center',
        },

        '& .MuiTableCell-head:last-child': {
          borderRight: 'none',
        },
        '& thead': {
          color: '#3a0ccf',
          border: '4px solid #3a0ccf',
          '& .MuiCheckbox-root': {
            color: '#ffffff',
            '&:checked': {
              color: '#ffffff',
              backgroundColor: '#ffffff',
              borderColor: '#ffffff',
            },
          },
          '& th': {
            fontWeight: 'bold',
            backgroundColor: '#3a0ccf',
            color: '#ffffff',
          },
        },
      },
    }),
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, newPage) => {
                const scrollPosition = window.scrollY;
                changePage(newPage);
                setTimeout(() => {
                  window.scrollTo(0, scrollPosition);
                }, 100);
              }}
              onRowsPerPageChange={(event) => {
                setIsLoading(true);
                const value = event.target.value;
                setPage(0);
                changeRowsPerPage(value);
              }}
              labelDisplayedRows={customPaginationTotalRender}
              rowsPerPageOptions={[10, 50, 100, 200, { label: 'All', value: 10000 }]}
            />
          </TableRow>
        </TableFooter>
      );
    },
  };

  const optionsTableCart = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    tableLayout: 'auto',
    selectedRows: 'none',
    tableBodyHeight: cart.length > 0 ? `${Math.min(400 + cart.length * 50, 600)}px` : '200px',
    draggableColumns: false,
    selectableRows: 'none',
    sort: true,
    pagination: false,
  };

  useEffect(() => {
    setSelectedRows((prev) => {
      if (prev.length > 0) {
        return prev.filter((index) => index < products.length);
      }
      return prev;
    });
  }, [products]);

  useEffect(() => {
    setPage(0);
  }, [products]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} flexWrap="wrap">
        {/* Button clear selected */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: buttonDisabled ? '#d3d3d3' : 'white',
            color: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: buttonDisabled ? 'not-allowed' : 'pointer',
            opacity: buttonDisabled ? 0.5 : 1,
            pointerEvents: buttonDisabled ? 'none' : 'auto',
          }}
          style={{ marginLeft: '10px' }}
          onClick={handleRemoveSelectedItems}
          disabled={buttonDisabled}
        >
          Clear selected
        </Button>

        {/* Button Download CSV */}
        <CSVLink data={getSelectedProducts()} filename="ComplexAntibodies-ProductSelection.csv" style={{ textDecoration: 'none' }}>
          <Button
            sx={{
              marginLeft: '10px',
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: buttonDisabled ? '#d3d3d3' : 'rgb(58, 12, 207)',
              color: buttonDisabled ? 'b0b0b0' : 'white',
              border: '4px solid',
              borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
              borderRadius: '5px',
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              opacity: buttonDisabled ? 0.5 : 1,
              pointerEvents: buttonDisabled ? 'none' : 'auto',
            }}
            disabled={buttonDisabled}
          >
            Download CSV
          </Button>
        </CSVLink>

        {/* Button Add to cart */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: buttonDisabled ? '#d3d3d3' : 'white',
            color: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: buttonDisabled ? 'not-allowed' : 'pointer',
            opacity: buttonDisabled ? 0.5 : 1,
            pointerEvents: buttonDisabled ? 'none' : 'auto',
          }}
          onClick={handleAddToCart}
          style={{ marginLeft: '10px' }}
          disabled={buttonDisabled}
        >
          Add to cart
        </Button>

        {/* Button Cart */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: 'white',
            color: 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: 'pointer',
            opacity: 1,
            alignSelf: selectedRows.length > 0 ? 'flex-start' : 'flex-end',
          }}
          onClick={() => setShowCartModal(true)}
          disabled={false}
        >
          Cart ({cart.length})
        </Button>
      </Box>

      {/* Main products table */}
      <div>
        {isLoading && (
          <LinearProgress
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          />
        )}
        <Box sx={{ width: '100%', overflowX: 'auto', marginTop: '10px' }}>
          <MUIDataTable title="" data={products} columns={columnsTable} options={{ ...optionsTable }} />
        </Box>
      </div>

      {/* Modal cart */}
      <Modal open={showCartModal} onClose={() => setShowCartModal(false)} sx={{ overflowY: 'auto' }}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '90%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            zIndex: 1400,
            // overflow: 'auto',
            overflowY: 'auto',
          }}
        >
          <button
            onClick={() => setShowCartModal(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '18px',
              cursor: 'pointer',
              color: '#000',
            }}
          >
            <span>&times;</span>
          </button>
          <h3>Selected Products (Cart)</h3>
          {cart.length > 0 ? (
            <div>
              {/* Table cart */}

              <Box sx={{ width: '100%', overflowX: 'auto', marginTop: '10px' }}>
                <MUIDataTable
                  data={cart.map((product) => ({
                    ...product,
                    action: (
                      <Button
                        onClick={() => handleRemoveItem(product.Sample_ID)}
                        style={{
                          backgroundColor: 'rgb(58, 12, 207)',
                          color: 'white',
                          padding: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        Remove
                      </Button>
                    ),
                  }))}
                  columns={[...columnsTable, { name: 'action', label: 'Actions' }]}
                  options={{ ...optionsTableCart, tableLayout: 'auto', pagination: false, responsive: 'standard' }}
                />
              </Box>

              {/* Button clear all cart */}
              <Button
                onClick={handleClearCart}
                sx={{
                  marginLeft: '10px',
                  marginTop: '20px',
                  padding: '10px 20px',
                  backgroundColor: 'white',
                  color: 'rgb(58, 12, 207)',
                  border: '4px solid',
                  borderColor: 'rgb(58, 12, 207)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Clear Cart
              </Button>

              {/* Button remove multiple cart */}
              <Button
                onClick={() => setShowRemoveModal(true)}
                sx={{
                  marginLeft: '10px',
                  marginTop: '20px',
                  padding: '10px 20px',
                  backgroundColor: 'white',
                  color: 'rgb(58, 12, 207)',
                  border: '4px solid',
                  borderColor: 'rgb(58, 12, 207)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Remove multiple
              </Button>

              {/* Modal Remove multiple */}
              {showRemoveModal && (
                <div style={{ background: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#fff',
                      padding: '20px',
                      borderRadius: '10px',
                      zIndex: 1500,
                      width: '300px',
                    }}
                  >
                    <button
                      onClick={() => setShowRemoveModal(false)}
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '18px',
                        cursor: 'pointer',
                        color: '#000',
                      }}
                    >
                      <span>&times;</span>
                    </button>
                    <h3>Remove Product</h3>
                    {uniqueProductNames.map((name) => (
                      <div key={name} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          checked={selectedProductNames.includes(name)}
                          onChange={() => handleCheckboxChange(name)}
                          style={{ marginRight: '8px' }} // Esto agrega espacio entre el checkbox y el nombre
                        />
                        <div>{name}</div>
                      </div>
                    ))}
                    <Button
                      onClick={handleRemoveMultipleItems}
                      sx={{
                        marginLeft: '10px',
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: 'rgb(58, 12, 207)',
                        color: 'white',
                        border: '4px solid',
                        borderColor: 'rgb(58, 12, 207)',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      Remove Selected
                    </Button>
                  </div>
                </div>
              )}

              {/* Csv */}
              <CSVLink data={cleanUpCart(cart)} filename={'ComplexAntibodies-ProductSelection.csv'}>
                <Button
                  sx={{
                    marginLeft: '10px',
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: 'rgb(58, 12, 207)',
                    color: 'white',
                    border: '4px solid',
                    borderColor: 'rgb(58, 12, 207)',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgb(58, 12, 207)',
                      boxShadow: 'none',
                    },
                  }}
                  disableElevation
                  onMouseEnter={(e) => e.preventDefault()}
                  onMouseLeave={(e) => e.preventDefault()}
                >
                  Download CSV
                </Button>
              </CSVLink>
            </div>
          ) : (
            <div>No products in the cart</div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Table;
